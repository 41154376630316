<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col><h1>{{$t("model.name.schools")}}</h1></v-col>
      <v-col class="text-right">
        <v-btn
          text
          large
          color="primary"
          class="mx-2"
          :to="{ name: 'PageModeratorSchoolQuickSetup' }"
        >
          + {{$t("action.quick_setup")}}
        </v-btn>
        <v-btn color="primary" large :to="{ name: 'PageModeratorSchoolAdd' }">
          + {{$t("action.new_school")}}
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[table] -->
    <BreadBrowseTable
      :role="role"
      :model="model"
      :url="`${$api.servers.moderator}/v1/school`"
      :isSearchable="true"
    ></BreadBrowseTable>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/school";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>